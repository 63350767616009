import React from 'react';
import Link from 'next/link';
import { sendGTMEvent } from '@next/third-parties/google';
import classNames from 'classnames/bind';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import { USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useAccount } from 'frontastic';
import styles from './PromoBanner.module.scss';
import { IPromoBannerProps } from './types/IPromoBannerProps';

const cx = classNames.bind(styles);
const PromoBanner = ({ data }: IPromoBannerProps) => {
  const { account } = useAccount();

  if (!data || !Boolean(data.promoMessagesGroup.length)) {
    return false as unknown as JSX.Element;
  }

  const { bannerBackgroundColor, bannerMessageTextColor, displayOnlyDesktop, promoMessagesGroup } = data;

  return (
    <div
      data-testid="promo-banner"
      className={cx('promo-banner', {
        singleMessage: promoMessagesGroup.length === 1,
        'd-none d-md-block': !displayOnlyDesktop,
      })}
      style={
        {
          ...(bannerBackgroundColor && { '--background-color': bannerBackgroundColor }),
          ...(bannerBackgroundColor && { '--text-color': bannerMessageTextColor }),
        } as React.CSSProperties
      }
    >
      <Carousel indicators={false} interval={8000} data-testid="promo-banner-carousel">
        {promoMessagesGroup.map(({ promoMessage, url, openInNewWindow, uid }) => {
          return (
            <CarouselItem
              key={uid}
              onClick={() =>
                sendGTMEvent({
                  event: 'select_promotion',
                  hit_time_stamp: Date.now(),
                  userId: account?.accountId,
                  userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
                  ecommerce: {
                    creative_name: `${promoMessage}_creative`,
                    creative_slot: `${promoMessage}_slot`,
                    promotion_id: uid,
                    promotion_name: promoMessage,
                  },
                })
              }
            >
              {url && (
                <Link href={url}>
                  <a data-testid={'promo-link'} target={openInNewWindow ? '_blank' : '_self'}>
                    {promoMessage}
                  </a>
                </Link>
              )}

              {!url && <p data-testid={'promo-message'}>{promoMessage}</p>}
            </CarouselItem>
          );
        })}
      </Carousel>
    </div>
  );
};

export default PromoBanner;
